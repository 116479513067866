// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/bripplex/bripplexScreenshot2.png";
import bgImage2 from "assets/images/bripplex/bripplexScreenshot1.png";
import bripplexLogo from "assets/images/bripplex/fulllogo.png";

function OurProjects() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={5}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKBox
            width="3rem"
            height="3rem"
            borderRadius="lg"
            shadow="md"
            variant="gradient"
            bgColor="warning"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon fontSize="small" sx={{ opacity: 0.8 }}>
              settings
            </Icon>
          </MKBox>
          <MKTypography variant="h3" mt={3}>
            Our current projects
          </MKTypography>
          <MKTypography variant="body2" color="text">
            These are the projects we are working on
          </MKTypography>
        </Grid>
        <MKBox component="section" textAlign="center">
          <img src={bripplexLogo} height={"200"} />
        </MKBox>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={bgImage1}
              backgroundSize={"contain"}
              label="BRIPPLEX APP"
              title="Put your mobile device to work!"
              description="Earn money by sharing you mobile phone location and sensors data!"
              action={{
                type: "external",
                route: "https://bripplex.com",
                label: "Download app",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title="Your phone does the work!">
              Share your device sensors data 100% anonimously and let data consumers buy it from
              you.
            </ListItem>
            <ListItem title="100% anonymous">
              We do not store or share any personal information. Only sensor data such as longitude,
              latitude, and pressure are collected.
            </ListItem>
            <ListItem title="Blockchain technology">
              Data access is managed securely through blockchain technology, ensuring transparency
              and reliability. Payments are made in cryptocurrency for added security and
              convenience.
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <ListItem title="Data from all over the world">
              Sensor&apos;s data from mobile devices globally
            </ListItem>
            <ListItem title="Request data from the Bripplex community!">
              Add a region that needs data and we will notify the community to get it for you!
            </ListItem>
            <ListItem title="Buy data directly from Bripplex community">
              We use blockchain technology to make it secure and fast!
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <DefaultBackgroundCard
              image={bgImage2}
              label="BRIPPLEX.COM"
              title="Sensors data available for you!"
              description="Review and purchase sensor's data submited by the Bripplex community in seconds!"
              action={{
                type: "external",
                route: "https://bripplex.com/consumer",
                label: "Review",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default OurProjects;
