/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { useEffect, useState } from "react";
import { db } from "configuration/firebase";
import moment from "moment";
import { Snackbar } from "@mui/material";

function ContactUs() {
  const initialState = { fullName: "", email: "", description: "" };
  const [message, setMessage] = useState(initialState);
  const [isDisabled, setIsDisabled] = useState(true);
  const [snackbar, setSnackBar] = useState({ show: false, message: "" });

  useEffect(() => {
    if (message.description.length < 5 || message.fullName.length < 3 || message.email < 5)
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [message]);

  const handleSendMessage = async () => {
    try {
      await db
        .collection("messages")
        .doc()
        .set({ ...message, timestamp: moment().unix() });
      setMessage(initialState);
      setSnackBar((current) => ({
        ...current,
        message: "Your message was submitted. Sit tight wile we review it",
        show: true,
      }));
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      setSnackBar((current) => ({
        ...current,
        message: "There was an error submitting your message. Please refresh and try again.",
        show: true,
      }));
    }
  };

  const handleClose = () => {
    setSnackBar({ show: false, message: "" });
  };
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions, including partnership opportunities, please email
                sales@bripplex.com or contact using our contact form.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      value={message.fullName}
                      onChange={(val) =>
                        setMessage((current) => ({ ...current, fullName: val.target.value }))
                      }
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      value={message.email}
                      onChange={(val) =>
                        setMessage((current) => ({ ...current, email: val.target.value }))
                      }
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="What can we help you with?"
                      value={message.description}
                      onChange={(val) =>
                        setMessage((current) => ({ ...current, description: val.target.value }))
                      }
                      placeholder="Let us know what you think"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    disabled={isDisabled}
                    onClick={handleSendMessage}
                  >
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />
    </>
  );
}

export default ContactUs;
